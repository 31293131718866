@import url("https://fonts.googleapis.com/css2?family=Cairo:wght@400;700;1000&display=swap");
body {
  font-family: "Cairo", sans-serif;
  font-size: 18px;
  color: #382843;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Cairo", sans-serif;
  font-weight: 1000;
}
.App {
  text-align: center;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.App-link {
  color: #61dafb;
}
.link {
  cursor: pointer;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.logo-pasaporte {
  width: 200px;
}
@media (max-width: 768px) {
  .logo-pasaporte,
  .textos-banner {
    max-width: 145px !important;
  }
}

.hamburguer-menu,
.bell-notification {
  background-color: transparent !important;
  box-shadow: none !important;
}

.btn-close {
  width: 1.5em;
  height: 1.5em;
  background: transparent
    url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23000%27%3e%3cpath d=%27M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z%27/%3e%3c/svg%3e")
    center/1.5em auto no-repeat;
  opacity: 1;
}
.sidenav {
  width: 360px;
}
@media (max-width: 768px) {
  .sidenav {
    width: 75% !important;
  }
}
.sidenav-link:hover {
  color: #ff188a;
  background-color: #f5f4f9;
  outline: none;
}
.side-close-session {
  position: absolute;
  bottom: 40px;
  width: 100%;
}
.btn-outline-dark {
  color: #382843 !important;
  border: 3px solid #382843;
  border-radius: 4px;
  font-weight: 700;
  font-size: 20px;
  line-height: 39px;
}
.button-primary {
  background-color: #9063da !important;
  color: #ffffff !important;
  border: 3px solid #382843;
  box-shadow: none !important;
}
.button-primary:hover {
  background-color: #7f56c0 !important;
  border: 3px solid #382843;
}
.button-secondary {
  background-color: #25CEAD !important;
  color: #382843 !important;
  border: 3px solid #382843;
  box-shadow: none !important;
}
.button-secondary:hover {
  background-color: #1fb99a !important;
  color: #382843 !important;
  border: 3px solid #382843;
}
.button-pink {
  background-color: #ff188a !important;
  color: #ffffff !important;
  border: 2px solid #382843;
  box-shadow: none !important;
}
.button-pink:hover {
  background-color: #df167a !important;
  color: #ffffff !important;
  border: 3px solid #382843;
}
.button-light {
  background-color: #f3f3f7;
  color: #382843;
  border: 3px solid #382843;
  box-shadow: none !important;
}
.button-light:hover {
  background-color: #e4e6ec;
  color: #382843;
  border: 3px solid #382843;
}
.form-outline .form-control {
  padding-top: 0.6em !important;
  padding-bottom: 0.6em !important;
}
/* unfocused borders and label*/
.form-outline .form-control ~ .form-notch .form-notch-trailing {
  border-top: 0.2em solid #382843;
  border-right: 0.2em solid #382843;
  border-bottom: 0.2em solid #382843;
}
.form-outline .form-control ~ .form-notch .form-notch-middle {
  border-bottom: 0.2em solid #382843;
  border-top: 0.2em solid #382843;
}
.form-outline .form-control ~ .form-notch .form-notch-leading {
  border-top: 0.2em solid #382843;
  border-bottom: 0.2em solid #382843;
  border-left: 0.2em solid #382843;
}
.form-outline .form-control ~ .form-label {
  font-size: 16px;
  color: #6c757d;
  background: #fff;
  border-radius: 4px;
}
/* focused borders and label*/
.form-outline .form-control ~ .form-notch:focus-visible {
  outline: 0 !important;
}
.form-outline .form-control:focus ~ .form-notch .form-notch-leading {
  border: none;
  border-top: 0.2em solid #ff188a !important;
  border-bottom: 0.2em solid #ff188a !important;
  border-left: 0.2em solid #ff188a !important;
  box-shadow: none;
}
.form-outline .form-control:focus ~ .form-notch .form-notch-trailing {
  border: none;
  border-top: 0.2em solid #ff188a !important;
  border-right: 0.2em solid #ff188a !important;
  border-bottom: 0.2em solid #ff188a !important;
  box-shadow: none;
}
.form-outline .form-control:focus ~ .form-notch .form-notch-middle {
  border: none;
  border-top: 0;
  border-bottom: 0.2em solid #ff188a !important;
  box-shadow: none;
}
.form-outline .form-control:focus ~ .form-label {
  color: #6c757d;
  padding-top: 0;
}
.form-outline .form-control {
  background: white !important;
}
.form-outline .form-control ~ .form-label {
  top: 5px;
}
.form-outline:focus .form-label {
  top: 20px !important;
}
input:focus + label {
  color: white;
}
.form-outline .form-control:disabled {
  background-color: #e8e9eb !important;
  color: #8d8e91;
}
.banner-admin {
  background-image: linear-gradient(
    90deg,
    #00dcf2 -5%,
    #9063da 41.48%,
    #ff188a 90.7%
  );
}
.banner-gradient {
  background-image: url(./img/tramas/texturaBannerWeb.svg),
    linear-gradient(90deg, #00dcf2 -5%, #9063da 41.48%, #ff188a 90.7%);
  background-position: center;
  background-size: cover;
}
@media (max-width: 768px) {
  .page-welcome {
    background-image: url(./img/tramas/fondoTextura-400x860.svg),
      linear-gradient(90deg, #00dcf2 -5%, #9063da 41.48%, #ff188a 90.7%) !important;
  }
  .banner-gradient {
    background-image: url(./img/tramas/texturaBannerMobile.svg),
      linear-gradient(90deg, #00dcf2 -5%, #9063da 41.48%, #ff188a 90.7%) !important;
  }
}
/* PAGES */
.page-welcome {
  background-image: url(./img/tramas/fondoTextura-1440x1024.svg),
    linear-gradient(180deg, #00dcf2 -5%, #9063da 41.48%, #ff188a 90.7%);
  background-position: center;
  background-size: cover;
}
.page-cubes {
  background-image: url(./img/tramas/Trama_Cubos_Full.svg);
  background-position: center;
  background-repeat: repeat;
  background-size: 110px;
}
@media (max-width: 768px) {
  .page-cubes {
    background-size: 85px;
  }
  .banner-gradient {
    padding: 1rem 0;
  }
}
.tag-level {
  background-color: #ff1889 !important;
  font-weight: 1000;
  font-size: 20px;
  border: 3px solid #556fdd;
  border-radius: 4px;
  color: #fff;
  width: fit-content;
  padding: 5px 10px;
  line-height: 22px;
  box-sizing: border-box;
}
.tag-prizes {
  background: #9063da !important;
  border: 2px solid #382843;
  font-weight: 700;
  font-size: 22px;
  border-radius: 4px;
  color: #fff;
  width: fit-content;
  margin: 0 auto;
  padding: 10px 20px;
  line-height: 30px;
  box-sizing: border-box;
}
.points-title {
  font-weight: 700;
  font-size: 22px;
  text-align: center;
}
.points-subtitle {
  font-weight: 1000;
  font-size: 34px;
  line-height: 38px;
  text-align: center;
  color: #ff188a;
  text-transform: uppercase;
}
.icon-cristal {
  position: absolute;
  bottom: 0;
  margin-left: -15px;
}
.gamerExperiencePoints,
.gamerCurrentScore {
  -webkit-text-fill-color: #fff;
  letter-spacing: -0.035em;
  font-weight: 1000;
  text-align: center;
  text-transform: uppercase;
  position: relative;
}
.gamerCurrentScore {
  font-size: 100px;
  line-height: 100px;
  -webkit-text-stroke: 6px #382843;
}
.gamerExperiencePoints {
  font-size: 100px;
  line-height: 100px;
  -webkit-text-stroke: 6px #382843;
}

.exp-points {
  font-weight: 1000;
  font-size: 100px;
  line-height: 100px;
  text-align: center;
  text-transform: uppercase;
  width: 100%;
}
.exp-points svg {
  /*display: block;*/
  height: 100px;
}
.exp-points text {
  fill: #fff;
  stroke: #382843;
  stroke-width: 6;
  letter-spacing: -0.035em;
}

.cohete {
  width: calc(50% + 8vh);
  text-align: end;
}
.cohetelinea {
  background-image: url(./img/cohete-linea.png);
  background-repeat: repeat-x;
  background-size: contain;
  width: 100%;
  height: 120px;
}
@media (max-width: 768px) {
  .cohetelinea,
  .cohete img {
    max-height: 100px;
  }
}
.panel-icons {
  color: #ff188a;
}
.page-admin {
  background-color: #f4f7fb;
}
.bg-admin {
  background-image: url(./img/bg-admin.jpg);
  background-repeat: no-repeat;
  background-size: auto;
  background-position: center;
}

/* Avatar */
.txt-selec-avatar,
.txt-register {
  width: 300px;
}
@media (max-width: 768px) {
  .txt-selec-avatar,
  .txt-register {
    width: 220px;
  }
}
.avatarselc-nombre {
  max-width: 50%;
  max-height: 50px;
}
.avatarimg {
  max-width: 300px;
}
.modal-cubes {
  background-image: url(./img/tramas/Trama_Cubos_Full.svg);
  background-position: center;
  background-repeat: repeat;
  background-size: 80px;
}
.edit-profile-link:hover,
.edit-profile-link:focus {
  background-color: transparent;
}
.input-password .form-outline {
  width: 100%;
  display: inline-flex;
  height: 44px;
}
.icon-button-eye {
  width: 55px;
  height: 44px;
  padding: 0 !important;
  background: transparent !important;
  color: #686868 !important;
  font-size: 17px;
  /*
  border-top: 0.17em solid #382843 !important;
  border-right: 0.17em solid #382843 !important;
  border-bottom: 0.17em solid #382843 !important;
  border-top-right-radius: .25rem;
  border-bottom-right-radius: .25rem;
  margin-left: -4px !important;
  */
  box-shadow: none !important;
  position: absolute !important;
  top: 0 !important;
  right: 0 !important;
  border-radius: 0 !important;
}
.login-admin #loginPassword {
  width: calc(100% - 55px) !important;
}
.password-selected {
  border-top: 0.2em solid #ff188a !important;
  border-right: 0.2em solid #ff188a !important;
  border-bottom: 0.2em solid #ff188a !important;
}

.password-selected2 {
  border-top: 0.2em solid #ff188a !important;
  border-right: 0.2em solid #ff188a !important;
  border-bottom: 0.2em solid #ff188a !important;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #ffffff;
  border-color: #9063da;
  background: #9063da;
  border-radius: 4px 4px 0px 0px;
}

.select-wrapper .select-arrow {
  color: #686868 !important;
  font-size: 1.4rem;
  right: 16px;
  top: 3px !important
}
.select-wrapper .select-arrow::before {
  font-family: "Font Awesome 5 Free" !important;
  content: "\f0d7" !important;
  display: inline-block;
  padding-right: 3px;
  vertical-align: middle;
  font-weight: 900;
}
.form-text.mb-3 {
  font-size: 0.8em;
}
#rrss-text > a {
  font-size: 22px;
  color: #382843;
}
#rrss-text > a:hover {
  color: #ff188a;
}
#offcanvas-close {
  background: none;
  box-shadow: none;
}
.nav-tabs .nav-item {
  border-bottom: 1px solid #382843;
}
.nav-tabs .nav-item a {
  color: #382843;
  font-size: 14px;
}
.badge-success {
  background-color: #ffd628;
  color: #382843;
}

h2.accordion-header button {
  font-weight: 600 !important;
  color: #382843 !important;
  font-size: 18px !important;
}